import { render, staticRenderFns } from "./template.vue?vue&type=template&id=626fbcbc&"
import script from "@/Components/Global/DataTable/component.ts?vue&type=script&lang=ts&"
export * from "@/Components/Global/DataTable/component.ts?vue&type=script&lang=ts&"
import style0 from "@/Components/Global/DataTable/style.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports