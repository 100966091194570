// Constants
import { Component } from '@/Constants/Component'
const { DetailsFlags } = Component.VueComponents.Global.DataTable

// Export Class
export class RecordHelper {
	public static parseFlags(flags: string) {
		// Separar las partes del 'String'.
		const keyFlags = flags.split('|')
		const parsedObject: Component.VueComponents.Global.DataTable.ParsedFlagsObject = { flagKey: '', text: keyFlags.shift() }

		// Asignar las posibles 'Flags' encontradas en el Array.
		for (const str of keyFlags) {
			const split = str.split('=')
			switch (split[0]) {
				case DetailsFlags.FLAG_KEY       : parsedObject.flagKey = split[1]; break
				case DetailsFlags.FLAG_SEARCH_KEY: parsedObject.flagSearchKey = split[1]; break
			}
		}

		// Retornar Objeto.
		return parsedObject
	}

	public static setFlags(title: string, key: string, searchKey?: string) {
		// Array con las distintas 'partes' del String correspondiente al 'Campo del Detalle'.
		const parts: Array<string> = [title]

		// Verificar cada posible 'Flag'.
		parts.push(`${ DetailsFlags.FLAG_KEY }=${ key }`)
		parts.push(`${ DetailsFlags.FLAG_SEARCH_KEY }=${ searchKey || key }`)

		// Retornar como un unico 'String' separados por '|'.
		return parts.join('|')
	}
}