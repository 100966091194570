// Constants
import { AppValues } from '@/Constants/AppValues'

// Class Export
export class ItemMissingFields {
	private fields: { [key: string]: { text: string } }

	constructor() {
		this.fields = {}
	}

	public add(field: string, text: (string | { $text: string }) = AppValues.Strings.DEFAULT_EMPTY_STRING): ItemMissingFields {
		this.fields[field] = typeof text === 'string' ? { text } : { text: text.$text }
		return this
	}

	public getField(key: string) {
		return this.fields[key]
	}

	public getFields() {
		return this.fields
	}
}